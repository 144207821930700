<template>
  <div>
    <v-data-table :headers="headers" :items="items" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Order Information</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <!-- <button type="button" class="btn btn-info px-4 py-2 ls1 mb-2">
            Import
          </button> -->
        </v-toolbar>
      </template>
      <template v-slot:item.no_of_skus="{ item, header, value }">
        {{ item.skus.length }}
      </template>
      <template v-slot:item.action="{ item, header, value }">
        <v-btn @click="editSku(item)" class="mx-2" fab x-small color="primary">
          <span class="svg-icon">
            <v-icon size="18">mdi-pencil</v-icon>
          </span>
        </v-btn>
      </template>
    </v-data-table>
    <EditSkus
      v-on:updateOrderDetails="updateOrderDetails"
      ref="editSkus"
      :items="items"
    ></EditSkus>
  </div>
</template>

<script>
import EditSkus from "@/own/components/fulfillment/returned/internal/EditSkus.vue";
// import { SET_EDITED_SKU } from "@/core/services/store/returned.module";

export default {
  name: "SkuDatatable",
  components: { EditSkus },
  props: ["items"],
  data: () => ({
    selectedOrder: null,
  }),
  methods: {
    editSku(item) {
      this.selectedOrder = item;
      this.$refs.editSkus.toggleModal(item);
    },
    updateOrderDetails(val) {
      this.$emit("updateOrderDetails", {
        order: this.selectedOrder,
        skus: val,
      });
    },
  },
  computed: {
    headers() {
      let headers = [
        { text: "Client Reference", value: "original_client_reference" },
        { text: "System Order No", value: "original_order_number" },
        { text: "AWB", value: "original_awb" },
        { text: "Country", value: "consignee_country" },
        { text: "No of SKUs", value: "no_of_skus" },
        { text: "Price", value: "price" },
        { text: "Currency", value: "currency" },
        { text: "Action", value: "action" },
      ];
      return headers;
    },
  },
};
</script>
