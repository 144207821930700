<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          Add
          {{
            addressType
              ? addressType.charAt(0).toUpperCase() + addressType.slice(1)
              : ""
          }}
          Address
        </h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div class="row">
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.name"
              class
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              class
              v-model="formData.company"
              label="Company"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-autocomplete
              @change="onCountryChange"
              v-model="formData.country"
              label="Country"
              item-text="text"
              item-value="index"
              :items="serverData.countries"
              clearable
              outlined
              dense
              :error-messages="countryErrors"
              @input="$v.formData.country.$touch()"
              @blur="$v.formData.country.$touch()"
            ></v-autocomplete>
          </div>
          <div class="col-12 col-sm-6">
            <v-combobox
              v-model="formData.state"
              :search-input.sync="state_text"
              :items="addressServerData.states"
              :disabled="isStatesLoaded"
              :rules="[validationValueCheck('state')]"
              item-text="title"
              item-value="title"
              :label="getSuggestedRegion('state')"
              persistent-hint
              outlined
              dense
              clearable
              :error-messages="stateErrors"
              @input="$v.formData.state.$touch()"
              @blur="$v.formData.state.$touch()"
              @change="onStateChange"
              @keyup="updateValue('state')"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ state_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'state',
                            formData.country,
                            onCountryChange,
                            state_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6">
            <v-combobox
              v-model="formData.city"
              :search-input.sync="city_text"
              :items="addressServerData.cities"
              :rules="[validationValueCheck('city')]"
              :disabled="isCitiesLoaded"
              item-text="title"
              item-value="title"
              :label="getSuggestedRegion('city')"
              persistent-hint
              outlined
              dense
              clearable
              :error-messages="cityErrors"
              @input="$v.formData.city.$touch()"
              @blur="$v.formData.city.$touch()"
              @change="onCityChange"
              @keyup="updateValue('city')"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ city_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'city',
                            formData.state.id,
                            onStateChange,
                            city_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6">
            <v-combobox
              v-model="formData.area"
              :search-input.sync="area_text"
              :items="addressServerData.areas"
              :rules="[validationValueCheck('area')]"
              item-text="title"
              item-value="title"
              :label="getSuggestedRegion('area')"
              persistent-hint
              clearable
              outlined
              :disabled="isAreasLoaded"
              dense
              @keyup="updateValue('area')"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      No results matching "<strong>{{ area_text }}</strong
                      >".
                      <button
                        class="btn btn-info pl-2"
                        @click="
                          createNewItem(
                            'area',
                            formData.city.id,
                            onCityChange,
                            area_text
                          )
                        "
                      >
                        Create new
                      </button>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-combobox>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              class
              v-model="formData.address"
              label="Address"
              clearable
              outlined
              dense
              :error-messages="addressErrors"
              @input="$v.formData.address.$touch()"
              @blur="$v.formData.address.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <VueTelInputVuetify
              v-model="formData.phone"
              label="Phone"
              type="number"
              clearable
              outlined
              dense
              :inputOptions="{ showDialCode: false, tabindex: 0 }"
              mode="international"
              disabledFetchingCountry
              defaultCountry="AE"
              :error-messages="phoneErrors"
              @input="$v.formData.phone.$touch()"
              @blur="$v.formData.phone.$touch()"
            ></VueTelInputVuetify>
          </div>
        </div>

        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center mt-10">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="clearFields"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click.prevent="submit()"
          >
            Confirm
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import {
  SET_CUSTOM_COLLECTION_ADDRESS,
  SET_CUSTOM_DESTINATION_ADDRESS,
  SET_DESTINATION_ADDRESS_TYPE,
  SET_COLLECTION_ADDRESS_TYPE,
} from "@/core/services/store/returned.module";

export default {
  name: "CustomAddressForm",
  mixins: [validationMixin],
  components: { VueTelInputVuetify },
  props: ["pageLoader", "serverData", "addressType"],
  validations() {
    return {
      formData: {
        name: { required },
        address: { required },
        city: { required },
        country: { required },
        phone: {
          required,
          numeric: helpers.regex(
            "numeric",
            /^(?:\+)?(?=(?:.*\d){5})[0-9\s]+$/i
          ),
        },
        state: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    addressServerData: {},
    state_text: null,
    city_text: null,
    area_text: null,
    formData: {
      name: null,
      company: null,
      country: null,
      city: null,
      state: null,
      address: null,
      phone: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearFields();
      } else {
        const collectionAddress = {
          ...this.$store.getters.getRETURNSCustomCollectionAddress,
        };
        const destinationAddress = {
          ...this.$store.getters.getRETURNSCustomDestinationAddress,
        };
        if (this.addressType === "collection" && collectionAddress) {
          this.formData = collectionAddress;
          this.fetchAddressDetails();
        } else if (this.addressType === "destination" && destinationAddress) {
          this.formData = destinationAddress;
          this.fetchAddressDetails();
        }
      }
      this.dialog = !this.dialog;
      this.pageLoader(false);
    },
    fetchAddressDetails() {
      const actionType = this.$store.getters.getRETURNSActionType;
      this.onCountryChange(this.formData.country, true)
        .then(() => {
          if (actionType === "edit") {
            this.formData.state = this.addressServerData.states.find(
              (state) => state.id === this.formData.state
            );
          }
        })
        .then(() => this.onStateChange(this.formData.state, true))
        .then(() => {
          if (actionType === "edit") {
            this.formData.city = this.addressServerData.cities.find(
              (city) => city.id === this.formData.city
            );
          }
        })
        .then(() => this.onCityChange(this.formData.city, true))
        .then(() => {
          if (actionType === "edit") {
            this.formData.area = this.addressServerData.areas.find(
              (area) => area.id === this.formData.area
            );
          }
        });
    },
    clearFields() {
      this.$v.$reset();
      this.addressServerData = {};
      this.state_text = null;
      this.city_text = null;
      this.area_text = null;
      this.formData = {
        name: null,
        company: null,
        country: null,
        city: null,
        state: null,
        address: null,
        phone: null,
      };
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    createNewItem(type, id, func, value = "") {
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    checkErrors() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "state":
          try {
            if (
              this.addressServerData.states.filter(
                (state) => state.title == this.state_text
              ).length === 0
            ) {
              validation = "Please, select valid state!";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;

        case "city":
          try {
            if (
              this.addressServerData.cities.filter(
                (city) => city.title == this.city_text
              ).length === 0
            ) {
              validation = "Please, select valid city!";
              // this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;

        case "area":
          try {
            if (
              this.addressServerData.areas.filter(
                (area) => area.title == this.area_text
              ).length === 0
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    async onCountryChange(val, safe = false, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.addressServerData.states = response.data.states;
            if (!safe) {
              this.formData.state = null;
              this.formData.city = null;
              this.formData.area = null;
            }
            let i = { ...this.addressServerData };
            this.addressServerData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            id = this.addressServerData.states.filter(
              (state) => state.title == val.title
            )[0].id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);

        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.addressServerData.cities = response.data.cities;

            if (!safe) {
              this.formData.city = null;
              this.formData.area = null;
            }
            let i = { ...this.addressServerData };
            this.addressServerData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    async onCityChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.addressServerData.cities.filter(
              (city) => city.title == val.title
            )[0].id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }
        this.pageLoader(true);
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.addressServerData.areas = response.data.areas;
            if (!safe) {
              this.formData.area = null;
            }
            let i = { ...this.addressServerData };
            this.addressServerData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "state":
          if (
            this.addressServerData.states.filter(
              (state) => state.title == this.state_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.state_text = "";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }, 2000);
          } else {
            this.onCollectionStateChange(
              this.addressServerData.states.filter(
                (state) => state.title == this.state_text
              )[0]
            );
          }

          break;
        case "city":
          if (
            this.addressServerData.cities.filter(
              (city) => city.title == this.city_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.city_text = "";
              // this.serverData.areas = null;
            }, 2000);
          } else {
            this.oncityChange(
              this.addressServerData.cities.filter(
                (city) => city.title == this.city_text
              )[0]
            );
          }
          break;
        case "area":
          if (
            this.addressServerData.areas.filter(
              (area) => area.title == this.area_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.address.area = this.addressServerData.areas.filter(
              (area) => area.title == this.area_text
            )[0].title;
          }
          break;
      }
    },
    submit() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        if (this.addressType === "collection") {
          this.$store.commit(SET_CUSTOM_COLLECTION_ADDRESS, this.formData);
          this.$store.commit(SET_COLLECTION_ADDRESS_TYPE, 4);
        } else if (this.addressType === "destination") {
          this.$store.commit(SET_CUSTOM_DESTINATION_ADDRESS, this.formData);
          this.$store.commit(SET_DESTINATION_ADDRESS_TYPE, 4);
        }
        this.toggleModal();
      }
    },
    getSuggestedRegion(type) {
      const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1);

      if (
        this.serverData.return_management &&
        this.serverData.return_management[`c_${type}`]
      ) {
        return `${typeCapitalize} (Suggested: ${
          this.serverData.return_management[`c_${type}`]
        })`;
      } else {
        return typeCapitalize;
      }
    },
  },
  computed: {
    nameErrors: function () {
      return this.handleFormValidation("name", this, "formData");
    },
    addressErrors: function () {
      return this.handleFormValidation("address", this, "formData");
    },
    cityErrors: function () {
      return this.handleFormValidation("city", this, "formData");
    },
    phoneErrors: function () {
      return this.handleFormValidation("phone", this, "formData");
    },
    mobileErrors: function () {
      return this.handleFormValidation("mobile", this, "formData");
    },
    countryErrors: function () {
      return this.handleFormValidation("country", this, "formData");
    },
    stateErrors: function () {
      return this.handleFormValidation("state", this, "formData");
    },
    isStatesLoaded: function () {
      return !this.addressServerData.states;
    },
    isCitiesLoaded: function () {
      return !this.addressServerData.cities;
    },
    isAreasLoaded: function () {
      return !this.addressServerData.areas;
    },
  },
};
</script>
